import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function BevelMemorials() {
  const data = useStaticQuery(graphql`
    query BevelMemorialQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "bevel" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Bevel Memorials"
      title="Bevel Memorials Gallery | Sheboygan Monument &amp; Stone Works"
      description="Bevel memorials that we have made for customers."
      urlPath="bevel-memorials"
    />
  );
}
